import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from './core/helpers/toast.service';
import { LoggerService } from './core/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  successMessage: string = null;
  errorMessage: string = null;

  unsub$ = new Subject<void>();

  @ViewChild('successTpl', { static: true }) successTpl: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: true }) dangerTpl: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.loggerService.successMessage$
      .pipe(takeUntil(this.unsub$))
      .subscribe((message: string) => {
        this.successMessage = message;
        this.toastService.show({
          template: this.successTpl,
          classname: 'bg-success text-light',
          delay: 4000,
        });
      });
    this.loggerService.errorMessage$
      .pipe(takeUntil(this.unsub$))
      .subscribe((message: string) => {
        this.errorMessage = message;
        this.toastService.show({
          template: this.dangerTpl,
          classname: 'bg-danger text-light',
          delay: 4000,
        });
      });
    this.authService.autoLogin();
    this.authService.logout$.pipe(takeUntil(this.unsub$)).subscribe(() => {
      this.router.navigate(['auth', 'login']);
    });
  }

  ngOnDestroy(): void {
    this.toastService.clear();
    this.unsub$.next();
    this.unsub$.complete();
  }
}
