@for (toast of toastService.toasts; track toast) {
<ngb-toast
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
</ngb-toast>
}
