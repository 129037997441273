import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * @var isRefreshing
   * It will act as a flag for refreshing (Set this while start the refresh)
   */
  isRefreshing = false;

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Getting the information of logged in user
    const user = this.authService.getUserInfo();
    const accessToken = user?.token;

    // If there is a access token then we will pass Authorization header.
    if (accessToken) {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + accessToken,
        }),
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        /**
         * If we encounter a 401 error, then we can try to generate new access token
         * with the refresh token
         */
        if (error?.status === 401) {
          this.authService.logout();
        }

        // Error is not 401, then we can throw the error
        return throwError(() => error);
      })
    );
  }
}
