<ng-template #successTpl>
  <div style="font-size: 15px; font-weight: 500">
    <i class="feather icon-alert-octagon"></i> {{ successMessage }}
  </div>
</ng-template>
<ng-template #dangerTpl>
  <div style="font-size: 15px; font-weight: 500">
    <i class="feather icon-moon"></i> {{ errorMessage }}
  </div>
</ng-template>
<app-toast-container
  aria-live="polite"
  aria-atomic="true"
></app-toast-container>
<router-outlet></router-outlet>
