import { Injectable, TemplateRef } from '@angular/core';

/**
 * Represents a toast notification with optional configuration.
 *
 * @interface Toast
 * @template T - The type of content that the toast will display.
 * @property {TemplateRef<T>} template - The template to be displayed as the toast content.
 * @property {string} [classname] - Optional. The CSS class name(s) to apply to the toast.
 * @property {number} [delay] - Optional. The delay in milliseconds before the toast automatically disappears.
 */
export interface Toast<T> {
  template: TemplateRef<T>;
  classname?: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * An array representing a collection of toast notifications.
   *
   * @property {Toast<any>[]} toasts - The array containing toast notifications.
   */
  toasts: Toast<any>[] = [];

  /**
   * Adds a toast to the collection of toasts.
   *
   * @method show
   * @memberof YourClassName
   * @param {Toast<any>} toast - The toast to be added.
   * @returns {void}
   */
  show(toast: Toast<any>): void {
    this.toasts.push(toast);
  }

  /**
   * Removes a specific toast from the collection.
   *
   * @method remove
   * @memberof YourClassName
   * @param {Toast<any>} toast - The toast to be removed.
   * @returns {void}
   */
  remove(toast: Toast<any>): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * Clears all toasts from the collection.
   *
   * @method clear
   * @memberof YourClassName
   * @returns {void}
   */
  clear(): void {
    this.toasts.splice(0, this.toasts.length);
  }
}
