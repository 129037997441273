import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { LocalForageService } from '../services/local-forage.service';
import { AuthenticatedUser } from '../../shared/models/authenticated-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private localForageService: LocalForageService,
    private authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user: AuthenticatedUser = this.authService.getUserInfo();
    if (user && user?.token) {
      return true;
    }
    return new Promise((resolve, reject) => {
      this.localForageService.getItem('user').then((user) => {
        if (!!user) {
          this.authService.autoLogin();
          resolve(true);
        } else {
          this.router.navigate(['/auth/login'], {
            queryParams: { returnUrl: state.url },
          });
          resolve(false);
        }
      });
    });
  }
}
